import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "24",
  height: "24",
  viewBox: "0 0 24 24",
  fill: "#212529"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M9.7002 6L15.7002 12L9.7002 18L8.3002 16.6L12.9002 12L8.3002 7.4L9.7002 6Z",
      fill: "inherit"
    }, null, -1)
  ])))
}
export default { render: render }